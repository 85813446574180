<template>
  <div
    class="card"
    :class="{ bazar: isReview, flexible }"
  >
    <Component
      :is="isReview ? 'div' : NuxtLink"
      :to="!isReview && !dragging ? card.route : undefined"
      draggable="false"
      class="card-content"
      @click="() => isReview && navigateTo(card.route)"
    >
      <div
        v-if="!isReview"
        class="header-container"
      >
        <div
          v-if="card.type === 'source'"
          class="header"
        >
          <div class="from">
            <span>
              {{ readMore(capitalize(card.user?.nickname), 25) }}
            </span>
            <NuxtImg
              v-if="card.user?.avatar.url"
              draggable="false"
              :src="card.user.avatar.url"
              :alt="`photo de profil de ${card.user.nickname}`"
              loading="lazy"
              quality="80"
              width="21"
              height="21"
              sizes="sm:21px tb:42px"
              fit="cover"
              format="auto"
            />
          </div>
          <div class="source">
            {{ card.domain }}
            <img
              v-if="card.favicon.url"
              :src="card.favicon.url"
              :alt="`favicon de ${card.domain}`"
              loading="lazy"
              width="16"
              height="16"
            />
          </div>
        </div>
        <div
          v-else-if="card.type === 'challenge' || card.type === 'actuality'"
          class="header header-challenge"
        >
          <div class="from">
            {{ readMore(capitalize(card.user?.nickname), 25) }}
            <NuxtImg
              v-if="card.user?.avatar"
              :src="card.user.avatar.url"
              :alt="`photo de profil de ${card.user.nickname}`"
              quality="80"
              width="21"
              height="21"
              sizes="sm:21px tb:42px"
              fit="cover"
              format="auto"
            />
          </div>
        </div>
        <div
          v-else
          class="header header-contrib"
        >
          <div class="from">
            <span>
              Écrit par
              {{ readMore(capitalize(card.user?.nickname), 25) }}
            </span>
            <NuxtImg
              v-if="card.user?.avatar"
              draggable="false"
              :src="card.user.avatar.url"
              :alt="`photo de profil de ${card.user.nickname}`"
              quality="80"
              width="21"
              height="21"
              sizes="sm:21px tb:42px"
              fit="cover"
              format="auto"
            />
          </div>
          <div class="contrib">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 14.91 14.854"
            >
              <path
                d="M19.609,8.152h0L16.846,5.406l-.05-.05a.908.908,0,0,0-1.191-.083,1.07,1.07,0,0,0-.149.132L6.327,14.52a.975.975,0,0,0-.281.695h0c-.017.05-.05.1-.066.149L5,19.1a.943.943,0,0,0-.017.215.561.561,0,0,0,.165.447.592.592,0,0,0,.414.182h.1a.621.621,0,0,0,.165-.017l3.755-.893a.513.513,0,0,0,.215-.1,1,1,0,0,0,.678-.281l9.13-9.13a.963.963,0,0,0,.265-.893A1.258,1.258,0,0,0,19.609,8.152ZM15.97,5.8a.249.249,0,0,1,.182-.066.266.266,0,0,1,.182.083l.05.05,2.746,2.746.017.017a.28.28,0,0,1,0,.38l-1.274,1.274a.28.28,0,0,1-.38,0L14.68,7.474a.241.241,0,0,1,0-.364Zm.877,5.524a.28.28,0,0,1-.38,0L13.654,8.516a.28.28,0,0,1,0-.38l.43-.43a.778.778,0,0,0,.149.2l2.812,2.812h0a.746.746,0,0,0,.232.165ZM6.807,19.035l-.877-.893.546-2.1,2.481,2.481Zm3.209-.844a.339.339,0,0,1-.48,0L6.79,15.446a.327.327,0,0,1-.1-.232.3.3,0,0,1,.1-.232l6.252-6.252a2.155,2.155,0,0,0,.165.248l2.812,2.812a.654.654,0,0,0,.248.165Z"
                transform="translate(-4.985 -5.091)"
                fill="#FFFFFF"
              />
            </svg>
          </div>
        </div>
      </div>
      <div class="img-container">
        <NuxtImg
          v-if="image"
          quality="80"
          draggable="false"
          :src="image"
          :alt="card.title"
          width="350"
          height="195"
          class="thumb"
          sizes="xs:100vw sm:100vw md:350px"
          format="auto"
        />
        <div
          v-if="card.is_star"
          class="recommended"
        >
          <img
            src="~/assets/icons/playstationWhite.svg"
            alt="logo PlayStation"
            class="logo"
            width="19"
            height="15"
          />
          <p>Recommandé par la Team WAP</p>
        </div>
        <div
          v-if="card.sub_category"
          class="sub-category"
        >
          {{ capitalize(card.sub_category.name) }}
        </div>
        <div
          v-if="card.type === 'challenge'"
          class="challenge-points"
        >
          <span>{{ card.points }}</span> pts à gagner
        </div>
      </div>
      <div
        v-if="isReview"
        class="score-container"
      >
        <div class="from">
          <NuxtImg
            v-if="card.user?.avatar"
            :src="card.user.avatar.url"
            :alt="`photo de profil de ${card.user.nickname}`"
            loading="lazy"
            width="42"
            height="42"
            quality="80"
            sizes="sm:42px tb:84px"
            fit="cover"
            format="auto"
            draggable="false"
          />
          {{ readMore(capitalize(card.user?.nickname), 25) }}
        </div>
        <div class="score">
          <ScoreResult :score="card.rating" />
        </div>
      </div>
      <div class="content">
        <h4
          v-if="isDesktop"
          class="text-card"
          :class="{ 'title-bzv': isReview }"
        >
          {{ readMore(capitalize(card.title), 83) }}
        </h4>
        <h4
          v-else-if="isReview"
          class="text-card title-bzv"
        >
          {{ readMore(capitalize(card.title), 30) }}
        </h4>
        <h4
          v-else
          class="text-card"
        >
          {{ readMore(capitalize(card.title), 60) }}
        </h4>

        <p
          v-if="isReview"
          class="text-bzv"
        >
          {{ readMore(capitalize(card.content), 200) }}
        </p>
        <ul
          class="tags"
          :class="{
            oneLine: card.title.length <= 40,
            'tags--bazar': isReview,
          }"
        >
          <li
            v-if="card.community"
            class="mainTag"
          >
            {{ card.community.title }}
          </li>
          <li
            v-for="tag in card.tags"
            :key="tag.name"
          >
            {{ tag.name }}
          </li>
        </ul>
      </div>
      <div class="footer">
        <div class="likes">
          {{ kFormatter(card.likes_count) }}
          <img
            src="~/assets/icons/like.svg"
            alt="j'aime"
            width="17"
            height="15"
          />
        </div>
        <div
          class="comments"
          to="/"
        >
          {{ kFormatter(card.comments_count) }}
          <img
            src="~/assets/icons/comment.svg"
            alt="commentaires"
            width="17"
            height="14"
          />
        </div>
        <div class="shares">
          {{ kFormatter(card.shares_count) }}
          <img
            src="~/assets/icons/share.svg"
            alt="partages"
            width="17"
            height="11"
          />
        </div>
      </div>
    </Component>
  </div>
</template>

<script setup lang="ts">
import { NuxtLink } from '#components'

const props = withDefaults(
  defineProps<{
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    card: Record<string, any>
    flexible?: boolean
    dragging?: boolean
  }>(),
  {
    flexible: false,
    dragging: false,
  },
)

const { isDesktop } = useCustomMediaQuery()

const isReview = computed(() => props.card.type === 'review')
const image = computed(
  () =>
    props.card.image?.url ??
    props.card.image_desktop?.url ??
    '/img/default-thumb.jpg',
)
</script>

<style lang="scss" scoped>
.card {
  display: flex;
  flex-direction: column;
  width: 35rem;
  height: 43.5rem;
  color: $dark;
  cursor: pointer;

  .card-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    color: inherit;
    text-decoration: inherit;
  }

  &.flexible {
    width: 100%;
  }

  &:hover {
    .img-container {
      .thumb {
        // transform: scale(1.3) rotate(-5deg);
        transform: scale(1.3);
      }
    }
  }

  &.bazar {
    height: 58rem;

    .score-container {
      display: flex;
      justify-content: space-between;
      height: 8rem;
      padding: 0 2rem;
      background: $black;

      .from,
      .score {
        display: flex;
        align-items: center;
        height: 100%;
        color: $white;
        font-size: 1rem;
        font-weight: 500;
        letter-spacing: 0.1rem;

        img {
          width: 4.2rem;
          height: 4.2rem;
          margin-right: 1rem;
          border-radius: 50%;
          object-fit: cover;
        }
      }
    }
  }

  .header {
    display: flex;
    flex-shrink: 0;
    width: 100%;
    height: 4rem;
    color: $white;
    font-size: 1rem;
    font-weight: 500;
    letter-spacing: 0.1rem;
    background: $dark;

    &.header-challenge {
      position: relative;

      .from {
        justify-content: flex-end;
        width: 100%;
        padding-right: 2rem;
      }

      .bonus {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        padding: 1rem 1.5rem;
        color: $white;
        font-family: 'SST Condensed';
        background: $orange;

        .small {
          font-size: 1rem;
          line-height: 1.3rem;
        }

        .big {
          font-size: 2.4rem;
          font-weight: bold;
          line-height: 2.6rem;
        }
      }
    }

    &.header-contrib {
      .from {
        span {
          max-width: 18rem;
        }
      }
    }

    .from,
    .source,
    .contrib {
      display: flex;
      align-items: center;
      height: 100%;
    }

    .from {
      flex-grow: 1;
      padding-left: 2rem;
      border-right: 1px solid #3c3c3c;

      span {
        display: block;
        flex-grow: 0;
        flex-shrink: 0;
        flex-wrap: nowrap;
        max-width: 8.5rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      img {
        width: 2.1rem;
        height: 2.1rem;
        margin-left: 1rem;
        border-radius: 50%;
        object-fit: cover;
      }
    }

    .contrib {
      padding-right: 2rem;
      padding-left: 2rem;

      svg {
        width: 1.49rem;
        height: 1.485rem;
      }
    }

    .source {
      justify-content: flex-end;
      width: 50%;
      padding-right: 2rem;
      border-left: 1px solid #2c2c2c;

      img {
        width: 1.6rem;
        margin-left: 1rem;
      }
    }
  }

  .img-container {
    position: relative;
    flex-shrink: 0;
    width: 100%;
    height: 19.5rem;
    overflow: hidden;
    background: #999;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: 0.4s;
    }

    .recommended {
      position: absolute;
      bottom: 0;
      left: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 2.6rem;
      padding: 0 2rem;
      color: $white;
      line-height: 2.6rem;
      background: $blue;

      p {
        font-size: 0.9rem;
      }

      img {
        width: 1.9rem;
        height: 1.5rem;
      }
    }

    .sub-category {
      position: absolute;
      bottom: 0;
      left: 0;
      height: 3.4rem;
      padding: 0 2.7rem;
      color: $white;
      font-size: 1rem;
      font-weight: 500;
      line-height: 3.4rem;
      letter-spacing: 0.15rem;
      text-transform: uppercase;
      background: $blue;
    }

    .challenge-points {
      position: absolute;
      bottom: 0;
      left: 0;
      padding: 0.5rem 1.5rem 0.5rem 1rem;
      color: $white;
      font-family: 'SST Condensed';
      font-size: 1.5rem;
      font-weight: bold;
      line-height: 3.1rem;
      text-transform: uppercase;
      background: $orange;
      border-top: 0.5rem solid $white;
      border-right: 0.5rem solid $white;

      span {
        font-size: 2.6rem;
      }
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 2.1rem 3rem 0;
    background: $white;
    border-right: 1px solid #ccc;
    border-left: 1px solid #ccc;

    h4 {
      display: -webkit-box;
      flex-shrink: 0;
      height: 4.5rem;
      padding-bottom: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    .text-bzv {
      font-size: 1.4rem;
      word-break: break-word;
    }

    .title-bzv {
      font-weight: 500;
    }

    .tags {
      display: flex;
      flex-shrink: 0;
      flex-wrap: wrap;
      align-items: flex-start;
      width: 100%;
      height: 4.8rem;
      margin-top: 1.5rem;
      padding: 0;
      overflow: hidden;
      list-style: none;

      &--bazar {
        margin-top: auto;
      }

      li {
        height: 1.9rem;
        margin-right: 0.5rem;
        margin-bottom: 0.5rem;
        padding: 0 1.5rem;
        color: #afafaf;
        font-size: 0.8rem;
        font-weight: 500;
        line-height: 1.9rem;
        text-transform: uppercase;
        background: #ebebeb;

        &:last-child {
          margin-right: 0;
        }

        &.mainTag {
          color: $white;
          background: $blue;
        }
      }
    }
  }

  .footer {
    display: flex;
    flex-shrink: 0;
    height: 6rem;
    background: $white;
    border: 1px solid #ccc;

    .likes,
    .comments,
    .shares {
      display: flex;
      flex-grow: 1;
      justify-content: center;
      align-items: center;
      height: 100%;
      font-size: 1.1rem;
      font-weight: 500;

      img {
        width: 1.7rem;
        margin-left: 1.2rem;
      }
    }

    .comments {
      color: inherit;
      text-decoration: inherit;
      border-right: 1px solid #ccc;
      border-left: 1px solid #ccc;
    }
  }
}

@media screen and (max-width: 1023px) {
  .card {
    width: 27.6rem;
    height: 40.7rem;

    &.flexible {
      width: 100%;
    }

    &.bazar {
      height: 55rem;

      .score-container {
        height: 6.2rem;
        padding: 0 1.4rem;
      }
    }

    .header {
      &.header-challenge {
        .from {
          padding-right: 1.8rem;
        }

        .bonus {
          padding: 1rem 1.5rem;

          .small {
            font-size: 1rem;
            line-height: 1.3rem;
          }

          .big {
            font-size: 2.4rem;
            line-height: 2.6rem;
          }
        }
      }

      .from {
        padding-left: 1.8rem;
      }

      .contrib {
        padding-right: 1.8rem;
        padding-left: 1.8rem;
      }

      .source {
        padding-right: 1.8rem;
      }
    }

    .img-container {
      height: 16.2rem;

      .sub-category {
        height: 2.9rem;
        padding: 0 2.2rem;
        font-size: 0.8rem;
        line-height: 2.9rem;
        letter-spacing: 0.12rem;
      }
    }
  }
}
</style>
